import {GetState, StoreExtraArgs} from '../types'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {navigateToGroup} from '../../../../commons/services/navigation'
import {getGroupId, getGroupSlug} from '../selectors/groups'

export const GET_CONNECTED_GROUP = createActions('GET_CONNECTED_GROUP')
export const GET_GROUP_ACTIVITY = createActions('GET_GROUP_ACTIVITY')
export const JOIN_GROUP = 'JOIN_GROUP'

export const getConnectedGroup = () => async (dispatch: Function, getState: GetState) => {
  try {
    const eventId = getState().event.id
    if (eventId && !eventId.includes('demo')) {
      const response = await dispatch(callAPI(GET_CONNECTED_GROUP, eventId))
      if (response && response.group?.id) {
        return dispatch(getGroupActivity({groupId: response.group.id}))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const getGroupActivity =
  ({groupId}: {groupId: string}) =>
  (dispatch: Function) =>
    dispatch(callAPI(GET_GROUP_ACTIVITY, groupId))

export const joinGroup =
  () =>
  async (dispatch, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    dispatch({type: JOIN_GROUP})
    const state = getState()
    const groupId = getGroupId(state)
    const slug = getGroupSlug(state)
    await navigateToGroup(slug, groupId, wixCodeApi)
  }
