import {
  isFeeAdded,
  saleScheduled,
  saleStarted,
  isFreeTicketDefinition,
  isDonationTicketDefinition,
} from '@wix/wix-events-commons-statics'
import {TicketWithFormattedSalePeriod} from '@wix/events-types'
import {SelectedTickets, State} from '../types'

export const getTickets = (state: State) => {
  if (state.demoTickets?.length) {
    return state.demoTickets
  }

  return state.tickets
}

export const getTicketById = (tickets: TicketWithFormattedSalePeriod[], id: string) =>
  tickets.find(ticket => ticket.id === id)

export const getTicketOrderIndexById = (tickets: wix.events.ticketing.TicketDefinition[], id: string) =>
  tickets.findIndex(ticket => ticket.id === id)

export const areAllTicketsSoldOut = (tickets: wix.events.ticketing.TicketDefinition[]) =>
  !tickets.find((ticket: wix.events.ticketing.TicketDefinition) => !!ticket.limitPerCheckout)

export const isFixedPriceTicketSelected = (
  tickets: wix.events.ticketing.TicketDefinition[],
  selectedTickets: SelectedTickets,
) => {
  const fixedPriceSelectedTickets = tickets.filter(
    definition =>
      !isFreeTicketDefinition(definition) &&
      !isDonationTicketDefinition(definition) &&
      Boolean(selectedTickets[definition.id]?.quantity),
  )
  return fixedPriceSelectedTickets.length > 0
}

export const isWixFeeTypeIsAdded = (ticket: wix.events.ticketing.TicketDefinition) =>
  isFeeAdded(ticket.wixFeeConfig?.type)

export const hasScheduledTickets = (tickets: wix.events.ticketing.TicketDefinition[]) =>
  Boolean(tickets.find(ticket => saleScheduled(ticket)))

export const hasTicketsOnSale = (tickets: wix.events.ticketing.TicketDefinition[]) =>
  Boolean(tickets.find(ticket => saleStarted(ticket)))
