import {isNoResponseEnabled, isRsvpOpen} from '@wix/wix-events-commons-statics'
import {RsvpStatus} from '@wix/events-types'
import {State} from '../types'
import {isMember} from './current-member-details'

export const getMemberRsvp = (state: State) => state.memberRsvp.rsvp

export const getMemberRsvpId = (state: State) => getMemberRsvp(state)?.id ?? null

export const isMemberEventRsvpReady = (state: State) => state.memberRsvp.loaded || !state.membersAreaEnabled

export const getMemberEventRsvpStatus = (state: State): RsvpStatus => {
  const memberRSVP = getMemberRsvp(state)
  return memberRSVP && isMember(state) ? memberRSVP.status : null
}

export const isMemberRsvpExists = (state: State): boolean =>
  state.membersAreaEnabled && getMemberEventRsvpStatus(state) !== null

export const resolveStatusTextTranslation = (state: State) => {
  const status = getMemberEventRsvpStatus(state)
  const noEnabled = isNoResponseEnabled(state.event)

  if (!isMemberRsvpExists(state)) {
    return 'members.updateRsvp.message.notGoing'
  } else if (status === RsvpStatus.WAITING) {
    return 'members.updateRsvp.message.waitlist'
  } else if (!noEnabled || status === RsvpStatus.YES) {
    return 'members.updateRsvp.message.going'
  } else {
    return 'members.updateRsvp.message.notGoing'
  }
}

export const resolveButtonTextTranslation = (state: State) => {
  const noEnabled = isNoResponseEnabled(state.event)

  if (!noEnabled && isMemberRsvpExists(state) && !isMemberAbleToFinishRsvp(state)) {
    return 'members.updateRsvp.button-cancel'
  } else {
    return 'members.updateRsvp.button-update'
  }
}

export const isMemberAbleToFinishRsvp = (state: State) =>
  isMemberRsvpExists(state) && getMemberEventRsvpStatus(state) === RsvpStatus.WAITING && isRsvpOpen(state.event)

export const getFormattedRsvpFullNameAndEmail = (state: State) => {
  const memberRsvp = getMemberRsvp(state)

  return {
    guestName: `${memberRsvp?.firstName ?? ''} ${memberRsvp?.lastName ?? ''}`,
    guestEmail: memberRsvp?.email ?? '',
  }
}
