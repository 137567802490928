import {
  hasSeatingPlan,
  InvoiceSelectedItems,
  isDonationTicketDefinition,
  getEventTicketLimitPerOrder,
  getPricingOption,
  getPricingOptions,
  isFreeTicketDefinition,
} from '@wix/wix-events-commons-statics'
import {SelectedTickets, State, SelectedTicketInfo} from '../types'
import {isDonationValid} from '../services/donations'
import {getTicketById, getTicketOrderIndexById, getTickets} from './tickets'
import {getSelectedSeatingTickets, getSelectedTicketsToPlaces, getPlace, getWholeTablePlace} from './seating/places'

export const getSelectedTickets = (state: State): SelectedTickets =>
  hasSeatingPlan(state.event) ? getSelectedSeatingTickets(state) : state.selectedTickets

export const getPreliminaryInvoiceItems = (state: State): InvoiceSelectedItems => {
  return Object.entries(state.selectedTickets).reduce((acc, [ticketId, {quantity, donation, pricingOptionIds}]) => {
    let priceOverrides: string[]
    if (donation) {
      priceOverrides = new Array(quantity).fill(donation)
    } else if (pricingOptionIds) {
      const ticket = getTicketById(getTickets(state), ticketId)
      priceOverrides = pricingOptionIds.map(pricingOptionId => getPricingOption(ticket, pricingOptionId).price.amount)
    }

    acc[ticketId] = {
      quantity,
      priceOverrides,
    }
    return acc
  }, {} as InvoiceSelectedItems)
}

export const getSelectedTicketsQuantity = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  return Object.keys(selectedTickets).reduce(
    (sum: number, ticketId: string) => (selectedTickets[ticketId].quantity ?? 0) + sum,
    0,
  )
}

export const areSelectedDonationsValid = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  return Object.keys(selectedTickets).reduce(
    (valid: boolean, ticketId: string) => valid && isSelectedTicketDonationValid(state, ticketId),
    true,
  )
}

export const isSelectedTicketDonationValid = (state: State, ticketId: string) => {
  const selectedTickets = getSelectedTickets(state)
  const ticket = getTicketById(getTickets(state), ticketId)
  if (selectedTickets[ticketId].quantity && isDonationTicketDefinition(ticket)) {
    const donation = selectedTickets[ticketId].donation
    return isDonationValid(ticket, donation)
  }
  return true
}

export const getSelectedTicketQuantity = (state: State, ticketId: string, pricingOptionId?: string) => {
  if (pricingOptionId) {
    return state.selectedTickets[ticketId]?.pricingOptionIds?.filter(id => id === pricingOptionId).length ?? 0
  }
  return state.selectedTickets[ticketId]?.quantity ?? 0
}

export const getSelectedTicketDonation = (state: State, ticketId: string) => {
  return state.selectedTickets[ticketId]?.donation
}

export const getSelectedTicketDonationError = (state: State, ticketId: string) => {
  return state.selectedTickets[ticketId]?.donationError
}

export const getSortedSelectedTickets = (state: State) => {
  const selectedTickets = getSelectedTickets(state)
  const nonZero = (ticketDefId: string) => Boolean(selectedTickets[ticketDefId]?.quantity)

  return Object.keys(selectedTickets)
    .filter(nonZero)
    .sort(sortTicketDefinitionIds(state))
    .reduce(
      (sortedSelectedTickets: SelectedTickets, id: string) => ({
        ...sortedSelectedTickets,
        [id]: selectedTickets[id],
      }),
      {},
    )
}

export const sortTicketDefinitionIds = (state: State) => (first: string, second: string) =>
  getTicketOrderIndexById(getTickets(state), first) - getTicketOrderIndexById(getTickets(state), second)

export const getSelectedTicketsArray = (state: State): SelectedTicketInfo[] =>
  Object.entries(getSortedSelectedTickets(state)).reduce((tickets, [ticketDefId, {quantity, pricingOptionIds}]) => {
    const selectedTicket = getTicketById(getTickets(state), ticketDefId)
    if (hasSeatingPlan(state.event)) {
      const ticketsToPlaces = getSelectedTicketsToPlaces(state)
      tickets.push(
        ...ticketsToPlaces[selectedTicket.id].placeIds.map((placeId, placeIndex) => ({
          ...selectedTicket,
          place: getPlace(state, placeId) ?? getWholeTablePlace(state, placeId),
          pricingOptionId: ticketsToPlaces[selectedTicket.id].pricingOptionIds?.[placeIndex],
        })),
      )
    } else {
      tickets.push(
        ...Array(quantity)
          .fill(selectedTicket)
          .map((ticket, index) => ({
            ...ticket,
            pricingOptionId: pricingOptionIds?.[index],
          })),
      )
    }
    return tickets
  }, [])

export const getTotalOrderRevenue = (state: State) => {
  const selectedTickets = getSelectedTickets(state)

  return Object.keys(selectedTickets).reduce(
    (sum, ticketId) =>
      sum + Number(getTicketById(getTickets(state), ticketId).price.amount) * selectedTickets[ticketId].quantity,
    0,
  )
}

export const getNonFreeSelectedTicketsCount = (state: State) => {
  const selectedTickets = getSelectedTickets(state)

  return Object.keys(selectedTickets)
    .filter(ticketId => getTickets(state).find(ticket => ticket.id === ticketId && !isFreeTicketDefinition(ticket)))
    .reduce((sum, ticketId) => sum + selectedTickets[ticketId].quantity, 0)
}

export const getReservationQuantities = (state: State): wix.events.ticketing.TicketReservationQuantity[] => {
  const selectedTickets = getSortedSelectedTickets(state)
  return Object.entries(selectedTickets).flatMap(([ticketDefinitionId, {quantity, donation, pricingOptionIds}]) => {
    let ticketDetails: wix.events.ticketing.TicketDetails[]

    if (pricingOptionIds) {
      const ticket = getTicketById(getTickets(state), ticketDefinitionId)
      const pricingOptionIdsCounts = getSelectedPricingOptionIdsCounts(ticket, pricingOptionIds)
      ticketDetails = pricingOptionIdsCounts.map(({pricingOptionId, count}) => ({
        pricingOptionId,
        capacity: count,
      }))
    }

    if (donation) {
      ticketDetails = [{priceOverride: donation, capacity: quantity}]
    }

    return {
      ticketDefinitionId,
      quantity,
      ticketDetails,
    }
  })
}
export const isTicketLimitReached = (state: State) =>
  getEventTicketLimitPerOrder(state.event) <= getSelectedTicketsQuantity(state)

export const getSelectedPricingOptionIdsCounts = (
  ticket: wix.events.ticketing.TicketDefinition,
  pricingOptionIds: string[],
): {pricingOptionId: string; count: number}[] =>
  getPricingOptions(ticket)
    .map(({id}) => ({
      pricingOptionId: id,
      count: pricingOptionIds.filter(pricingOptionId => pricingOptionId === id).length,
    }))
    .filter(({count}) => count)
